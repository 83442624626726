@charset "utf-8";


// ==================================================
// Fonts
// ==================================================
@import url(https://fonts.googleapis.com/css?family=Lato:400,300,400italic,300italic,700&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,300&subset=latin,latin-ext);

.color-b {
  color: $baseBlack;
}

h3,
.font-b {font-family: 'Lato', sans-serif; }

.font-c {font-family: 'Roboto Condensed', sans-serif;}

$sizeXSmall: 10px;
$sizeSmall: 12px;
$sizeMedium: 15px;
$sizeMediumX: 16px;
$sizeMediumXX: 17px;
$sizeH3Small: 18px;
$sizeH1: 50px;
$sizeH3: 22px;
$sizeH3Big: 26px;
$sizeH3XBig: 30px;




@charset "utf-8";

// FOLLOW
#block-follow-site {
  h3 {
    text-align: center;
  }
  .follow-links {
    text-align: center;
    margin: 5rem 0;
    .follow-link-wrapper {
      display: inline-block;
      margin: 0 1rem;
      a{
        text-indent: -9999px;
        height: 79px;
        padding-left: 77px;
        &.follow-link-facebook{
          background: url("../img/ico/social/fb.png") !important;
        }
        &.follow-link-instagram{
          background: url("../img/ico/social/instagram.png") !important;
        }
        &.follow-link-youtube{
          background: url("../img/ico/social/yt.png") !important;
        }
        &.follow-link-twitter{
          background: url("../img/ico/social/twitter.png") !important;
        }
        &.follow-link-vimeo{
          background: url("../img/ico/social/vimeo.png") !important;
        }
        &.follow-link-googleplus{
          background: url("../img/ico/social/gplus.png") !important;
        }
      }
    }
  }
}

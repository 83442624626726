.node-article {
  &.node-teaser {
    margin-bottom: 4rem;
    h3 {
      font-size: $sizeH3;
      color: $darkBlue;
      margin: 0 0 2rem;
      font-weight: 400;
    }
  }
}
.field-collection-view,
.field-collection-container {
  border: none;
}

.table-best-period-wrap {
  position: relative;
}
.table-best-period-wrap-ct {
  position: relative;
  z-index: 5;
}
.table-best-period-wrap-bg {
  position: absolute;
  z-index: 3;
  width: 88%;
  top: -2.1rem;
  left: 6%;
  border: none;
  hr {
    position: absolute;
    width: 100%;
    height: 2px;
    background: darken($chartMain,7%);
    border: none;
    &:nth-child(1) {
      top: 1.3rem;
    }
    &:nth-child(2) {
      top: 3.6rem;
    }
    &:nth-child(3) {
      top: 5.9rem;
    }
    &:nth-child(4) {
      top: 8.2rem;
    }
  }
}
.table-best-period {
  height: 100%;
  width: 100%;
  .tbp-head{
    width: 5%;
    height: 100%;
    vertical-align: top;
    span.item {
      vertical-align: top;
      display: block;
      padding: 0.2rem 3rem 0 1rem;
      position: relative;
      margin: 0.2rem 1.5rem 0.2rem 0;
      background: no-repeat right center;
      font-size: 12px;
      &.last {
        height: 2rem;
      }
      &:after {
        content: '';
      }
      .glyphicon {
        position: absolute;
        font-size: 4px;
        display: inline-block;
        @include border-radius(50%);
        color: $chartBG2;
        background: $chartBG1;
        padding: 0.2rem;
        top: 0.8rem;
        right: -1.2rem;
        display: none;
      }
      @for $i from 1 through 4 {
        &.item-#{$i}{
          color: nth($chartBG, 5 - $i);
          background-image: url("../img/ico/smile-0#{$i}-01.svg")
        }
      }
    }

  }

  .tbp-content {
    vertical-align: bottom;
    width: 95%;
    height: 100%;
    .items {
      height: 100%;
      width: 100%;
      .item {
        padding-right: 1.5em;
        padding-left: 1.5em;
        width: 8.3333%;
        height: 100%;
        vertical-align: bottom;
        span.bar {
          display: block;
          height: 0;
        }
        span.label {
          color: $greyBase;
        }
        @for $i from 1 through 4 {
          &.item-value-#{$i}{
            &:after {
              content: attr(data-lab);
              text-align: center;
              font-size: 10px;
              width: 100%;
              display: block;
              color: $chartBG4;
            }
            span.bar{
              background: nth($chartBG, $i);
              height: #{percentage($i/5)};
            }
          }
        }
      }
    }
  }
}

.node-hotel {
  &.node-teaser{
    margin-bottom: 30px;
    .content {
      position: relative;
      overflow: hidden;
      background: white;
      &:hover .desc{
        top: 0;
        background: rgba(0,0,0, 0.7);
      }
      img {
        width: 100%;
      }
      .ndh-wrap {
        padding: 2rem;
        text-align: center;
        font-weight: 700;
        h5 {
          text-transform: uppercase;
          font-size: $sizeH3Small;
          height:  2em;
        }
        span {
          font-size: $sizeSmall;
          height:  3em;
          display: inline-block;
        }
        .text-short {
          height: 4rem;
          display: block;
          overflow: hidden;
        }
      }
      .desc {
        cursor: pointer;
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 4rem;
        background: rgba(0,0,0, 0.1);
        color: white;
        top: 100%;
        transition: all 0.4s ease 0s; -moz-transition: all 0.4s ease 0s;  -webkit-transition: all 0.4s ease 0s;
      }
    }
  }
}
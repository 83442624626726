@charset "utf-8";

#block-views-fototopnd-block-1 {
  .content img {
    width: 100%;
  }
}
.banner-slider {
  ul {
    display: block;
    margin: 0;
    padding: 0;
    li {
      display: block;
      margin: 0;
      padding: 0;
    }
  }
  img {
    width: 100%;
  }
  .image-field-caption {
    display: block;
    position: absolute;
    width: 100%;
    padding: 1rem;
    text-align: center;
    bottom: 7rem;
    color: white;
    font-size: $sizeMedium;
    font-weight: 300;
    margin: 0;
    border: none;
    font-style: italic;
  }
}


// BX BANNER
.bx-wrapper {
  margin: 0;
  .bx-viewport {
    @include box-shadow(0,0,0);
    border: none;
    left: inherit;
  }
  img {
    width: 100%;
  }
  ul {
    display: block;
    margin: 0;
    padding: 0;
    li {
      display: block;
      margin: 0;
      padding: 0;
    }
  }
  .bx-pager,
  .bx-controls-auto{
    bottom: 10px;
    z-index: 10;
    &.bx-default-pager a {
      @include border-radius(50%);
      height: 12px;
      width: 12px;
      background: white;
      &.active {
        background: $blueHover;
      }
    }
  }
  .bx-next {
    right: 30px;
  }
  .bx-prev {
    left: 30px;
  }
  // my custom seting
  .banner-wraper {
    .description {
      position: absolute;
      right: 7%;
      bottom: 15%;
      .box-wraper{
        position: relative;
        text-align: right;
        color: white;
        padding-top: 80px;
        h5{
          font-size: 60px;
          font-weight: 300;
          position: absolute;
          top: 0;
          right: 0;
        }
        p {
          padding:1.5rem;
          font-size: 30px;
          background: rgba(0,0,0,0.15);
        }

      }
      @media (max-width: 1199px) {
        .box-wraper {
          padding-top: 55px;
          h5{
            font-size: 40px;
          }
          p {
            font-size: 20px;
            padding:1.2rem;
          }
        }
      }
      @media (max-width: 991px) {
        right: 5%;
        bottom: 12%;
        .box-wraper {
          padding-top: 45px;
          h5{
            font-size: 30px;
          }
          p {
            font-size: 18px;
            padding:0.8rem;
          }
        }
      }
      @media (max-width: 768px) {
        right: 3%;
        bottom: 10%;
        .box-wraper {
          padding-top: 35px;
          h5{
            font-size: 20px;
          }
          p {
            font-size: 14px;
            padding:0.5rem;
          }
        }
      }
    }
  }
}
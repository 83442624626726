@charset "utf-8";

/* CSS Document */
html, body {height: 100%;}
body { margin: 0; padding:0;  font-size: 15px; line-height: 19px; color: #121212;  }
body { }

.wow { visibility: hidden;}

.a-left { float:left;}
.a-right { float:right;}
.t-center { text-align:center;}
.t-right { text-align: right;}
.txt-medium {font-size: $sizeMedium;}

.region {
  position: relative;
}

.margin-tb-1 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

h3{
  font-size: $sizeH3;
  font-weight: 300;
}

#breadcrumb-box {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: $sizeSmall;
}

/*******************************************************************************
 * wraper-button
 * in center content inside
 ******************************************************************************/
.wraper-box{
  position: relative;
}
.wraper-box-button {
  position: relative;
  display: block;
  img {
    width: 100%;
  }
  @include buton-image(1.3,1.3);
}
.wr-box {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  .wrb-tab {
    display: table;
    width: 100%;
    height: 100%;
    .wrb-cel {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      width: 100%;
      height: 100%;
    }
  }
}
/*******************************************************************************
 * wraper-block
 * HOTEL | TRIP TYPE || and || OTHER
 ******************************************************************************/
.wr-video-wrap {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.wr-inspiration-wrap {
  .inspiration-item {
    padding: 0;
  }
  margin: 5rem 0;
}
.wr-hotel-wrap {
  background: $greyLight2;
  padding: 3rem 0;
}

.wr-bestperiod-wrap {
  //margin-top: 10rem;
  background: $chartMain;
  padding: 3rem 0;
}
.wr-price-wrap {
  background: $oragneBase;
  padding: 5rem 0;
  color: white;
}
.add-inside-padding {
  padding-left: 5%;
  padding-right: 5%;
}
@media (min-width: 992px) and (max-width: 1199px) {
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (max-width: 768px) {

}
.node-inspirations {
  .field-name-body {
    text-align: center;
  }
  .field-name-field-nd-video {
    margin: 6rem 0 4rem;
  }
  .wr-region-wrap {
    margin-top: 5rem;
    margin-bottom: 0;
    .wrb-cel {
      h3 {
        font-size: $sizeH1;
      }
    }
    .wraper-box-button .wrb-cel h3 {
      font-size: $sizeH3XBig !important;
    }
  }
  .wr-offer-wrap  {
    padding-top: 6rem;
    padding-bottom: 4rem;
    margin-bottom: 0;
  }

  &.node-teaser {
    .wraper-box-button {
      margin-bottom: 0;
    }
  }
}
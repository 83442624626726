@charset "utf-8";
#myboxregion-wraper {
  margin: 5rem 0 5rem;
  .mbw-content {
    position: relative;
    width: 100%;
    height: 100%;
    .box-mapmenu-wrpa {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      &:hover {
        .region {
          opacity: 0.6;
        }
      }
      .region {
        position: absolute;
        height: 10rem;
        transition: all 0.4s ease 0s; -moz-transition: all 0.4s ease 0s;  -webkit-transition: all 0.4s ease 0s;
        &:hover {
          opacity: 1;
        }
        .ri-box {
          width: 10rem;
          height: 10rem;
          @include border-radius(50%);
          cursor: pointer;
          padding: 1rem;
          background: $blueLight;
          transition: all 0.4s ease 0s; -moz-transition: all 0.4s ease 0s;  -webkit-transition: all 0.4s ease 0s;
          h5 {
            font-size: $sizeMedium;
            font-weight: 400;
            color: white;
            text-align: center;
            text-transform: uppercase;
            line-height: $sizeMedium;
          }
        }
        &:nth-child(1 ) { top: 31rem;  left: 53rem; }
        &:nth-child(2 ) { top: 8rem;   left: 48rem; }
        &:nth-child(3 ) { top: 8rem;   left: 12rem; }
        &:nth-child(4 ) { top: 21rem;  left: 21rem; }
        &:nth-child(5 ) { top: 38rem;  left: 22rem; }
        &:nth-child(6 ) { top: 12rem;  left: 78rem;}
        &:nth-child(7 ) { top: 32rem;  left: 86rem; }
        &:nth-child(8 ) { top: 20rem;  left: 61rem; }
        &:nth-child(9 ) { top: 46rem;  left: 95rem; }
        &:nth-child(10) { top: 35rem;   left: 70rem;}

        @media (max-width: 1199px) {
          width: 8rem;
          height: 8rem;

          &:nth-child(1 ) { top: 25rem;  left: 43rem; }
          &:nth-child(2 ) { top: 7rem;   left: 38rem; }
          &:nth-child(3 ) { top: 5rem;   left: 8rem; }
          &:nth-child(4 ) { top: 17rem;  left: 15rem; }
          &:nth-child(5 ) { top: 31rem;  left: 18rem; }
          &:nth-child(6 ) { top: 9rem;   left: 63rem;}
          &:nth-child(7 ) { top: 26rem;  left: 71rem; }
          &:nth-child(8 ) { top: 16rem;  left: 50rem; }
          &:nth-child(9 ) { top: 38rem;  left: 75rem; }
          &:nth-child(10) { top: 31rem;  left: 58rem;}
        }
        @media (max-width: 991px) {
          &:nth-child(1 ) { top: 20rem;  left: 32rem; }
          &:nth-child(2 ) { top: 5rem;   left: 28rem; }
          &:nth-child(3 ) { top: 3rem;   left: 5rem; }
          &:nth-child(4 ) { top: 12rem;  left: 11rem; }
          &:nth-child(5 ) { top: 23rem;  left: 12rem; }
          &:nth-child(6 ) { top: 6rem;   left: 47rem;}
          &:nth-child(7 ) { top: 17rem;  left: 56rem; }
          &:nth-child(8 ) { top: 11rem;  left: 37rem; }
          &:nth-child(9 ) { top: 29rem;  left: 57rem; }
          &:nth-child(10) { top: 24rem;  left: 44rem;}
        }

        .wrb-cel-wrap {
          min-height: 10rem;
          height:    16rem;
          min-width: 18rem;
          position: relative;
          list-style: none;
          background: $blueLight;
          top: -3rem;
          left: 14rem;
          padding: 0.4rem;
          opacity: 0;
          transition: all 0.2s ease 0s;
          -moz-transition: all 0.2s ease 0s;
          -webkit-transition: left 0.2s ease 0s;
          display: block;
          &:before {
            $arrowSize: 0.8rem;
            width: 0;
            height: 0;
            border-top: $arrowSize solid transparent;
            border-bottom: $arrowSize solid transparent;
            border-right: $arrowSize solid $blueLight;
            content: ' ';
            display: block;
            position: absolute;
            left: -$arrowSize;
            top: 8rem;
            margin-top: -$arrowSize;
          }
          ul {
            display: block;
            margin: 0;
            padding: 0;
            li {
              margin: 0;
              padding: 0;
              display: block;
              a {
                display: block;
                color: white;
                text-align: center;
                padding: 0.5rem 2rem;
                width: 100%;
                font-weight: 400;
                font-size: $sizeMedium;
                border-bottom: 1px solid darken( $blueLight, 5% );;
                &:hover {
                  background: darken( $blueLight, 5% );
                  color: $oragneBase;
                }

              }
              &:last-child a{
                border: none;

              }
            }
          }
        }
        &:hover {
          z-index: 20;
          .ri-box {
            background: $oragneBase;
          }
          .wrb-cel-wrap  {
            display: block;
            opacity: 1;
            left: 11rem;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
}
@charset "utf-8";

#pagebottom-menu {
  margin-top: 5rem;
  margin-bottom: 2rem;
  .menu-item {
    a {
      h4,
      p {
        color: white;
        padding: 1rem;
        text-align: center;
        margin: 0;
      }
      h4 {
        font-size: $sizeH3;
        text-transform: uppercase;
        font-weight: 700;
        margin-top: 4rem;
        transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s;  -webkit-transition: all 0.3s ease 0s;
      }
      p {
        font-size: $sizeMedium;
        font-style: italic;
        opacity: 0;
        padding: 0 2rem;
        transition: all 0.4s ease 0s; -moz-transition: all 0.4s ease 0s;  -webkit-transition: all 0.4s ease 0s;
        height: 4rem;
      }
      &:hover {
        h4 {
          margin-top: 0;
        }
        p {
          opacity: 1;
        }
      }
    }
  }
}
.page-regions,
.page-trip-time,
.page-inspirations,
.page-katalog-ofert,
.page-triptypes {
  #pagebottom {
    padding: 2rem;
    background: $greyLight2;
  }
}
/*******************************************************************************
 * other inspiration
 ******************************************************************************/
#block-views-inspiration-block-2 {
  > h3 {
    text-align: center;
    font-size: $sizeH3XBig;
    margin: 2rem 0;
    font-weight: 700;
    text-transform: uppercase;
  }
}


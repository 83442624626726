@charset "utf-8";

a {transition: all 0.4s ease 0s; -moz-transition: all 0.4s ease 0s;  -webkit-transition: all 0.4s ease 0s; text-decoration: none !important; outline: none; color:$blue;}
a:active,
a:hover { color:$blueHover; text-decoration: none;}

/*******************************************************************************
 * basic style to read more button
 ******************************************************************************/
a.read-more {
  font-size: $sizeH3Small;
  color: white;
  display: inline-block;
  padding: 2rem 4rem;
  border: 1px solid white;
  &:hover {
    background: rgba(255,255,255, 0.2);
  }

  @media (max-width: 1199px) {
    padding: 1.5rem 3rem;
  }
  @media (max-width: 991px) {
    padding: 1rem 3rem;
    font-size: $sizeMedium;
  }
  @media (max-width: 768px) {
    padding: 0.5rem 2rem;
  }
}

/*******************************************************************************
 * sub menu
 ******************************************************************************/
.sub-menu {
  margin: 0.25rem 0 0;
  padding: 0;
  &.menu-region{
    background: $oragneBase;
  }
  ul {
    display: block;
    float: right;
    margin: 0;
    padding: 0;
    li {
      display: block;
      float: left;
      margin: 0 0.1rem;
      a {
        display: block;
        color: white;
        padding: 0.4rem 0.8rem;
        text-transform: uppercase;
        font-size: $sizeSmall;
        background: left top repeat-x url("../img/bg-submenu-region-hover.png");
        &.active,
        &:hover{
          background-position: left bottom;
        }
      }
    }
  }
}

/*******************************************************************************
 * filter region button
 ******************************************************************************/
.filter-options-wraper {
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  margin: 0 auto;
}
.filter-options{
  &.row {
    margin-left: -5px;
    margin-right: -5px;
    text-align: center;
    .col-md-1 {
      padding-right: 5px;
      padding-left: 5px;
    }
  }

  .bt-wraper {
    &.bt-wraper-w {
      width: 88px;
      display: inline-block;
      margin-left: 5px ;
      margin-right: 5px;
    }
    @include border-radius(50%);
    background: $blueLight;
    height: 88px;

    margin-bottom: 2rem;
    transition: all 0.4s ease 0s; -moz-transition: all 0.4s ease 0s;  -webkit-transition: all 0.4s ease 0s;
    cursor: pointer;


    &.active {
      background: $yellowMedium !important;
    }
    &:hover {
      background: $pinkHover;
    }
    span{
      display: table;
      width: 100%;
      height: 100%;
    }
    a.btn-filter {
      color: white;
      font-weight: 400;
      font-size: $sizeSmall;
      padding: 0.5rem;
      text-align: center;
      text-transform: uppercase;
      display: table-cell;
      vertical-align: middle;
      width: 100%;
      line-height: $sizeSmall;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      height: 88px;
      &.bt-wrp-time {
        height: 68px;
      }
      a.btn-filter {
        font-size: $sizeXSmall + 1;
      }
    }

    @media (min-width: 768px) and (max-width: 991px) {
      &.bt-wraper-w {
        width: 78px;
      }
      height: 78px;
      &.bt-wrp-time {
        height: 50px;
      }
      a.btn-filter {
        font-size: $sizeXSmall;
      }
    }

    @media (max-width: 768px) {
      &.bt-wraper-w {
        width: 57px;
        margin-left: 2px;
        margin-right: 2px;
      }
      height: 57px;
      &.bt-wrp-time {
        height: 40px;
        a.btn-filter {
          font-size: $sizeMedium;
        }
      }
      a.btn-filter {
        font-size: $sizeXSmall;
        letter-spacing: -1px;
        line-height: $sizeXSmall + 2;
      }
    }
    @media (max-width: 568px) {
      &.bt-wraper-w {
        width: 54px;
        margin-left: 1px;
        margin-right: 1px;
      }
      height: 54px;
      &.bt-wrp-time {
        height: 40px;
        a.btn-filter {
          font-size: $sizeMedium;
        }
      }
      a.btn-filter {
        font-size: $sizeXSmall - 1;
        letter-spacing: -1px;
        line-height: $sizeXSmall + 2;
      }
    }
  }
}
.view-display-id-page_2 {
  .filter-options {
    a.btn-filter {
      font-size: $sizeH3;
    }
  }
}


/*******************************************************************************
 * main button animation
 ******************************************************************************/
a.read-more {
  overflow: hidden;
  z-index: 1;
  position:relative;
  &:hover {
    z-index: 6;
  }
  &:before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    height: 50%;
    width: 100%;
    z-index: -2;
    opacity: 1;
    overflow: hidden;
    cursor: pointer;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    background: white;
    height: 50%;
    width: 100%;
    z-index: -2;
    opacity: 1;
    overflow: hidden;
    cursor: pointer;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  &:hover::before {
    opacity: 0;
    cursor: pointer;
    -webkit-transform: translateY(-100%) translateZ(0);
    -ms-transform: translateY(-100%) translateZ(0);
    transform: translateY(-100%) translateZ(0);
  }
  &:hover::after {
    opacity: 0;
    cursor: pointer;
    -webkit-transform: translateY(100%) translateZ(0);
    -ms-transform: translateY(100%) translateZ(0);
    transform: translateY(100%) translateZ(0);
  }
}
a.read-more {
  &:before {
    -webkit-transform: translateY(-100%) translateZ(0);
    -ms-transform: translateY(-100%) translateZ(0);
    transform: translateY(-100%) translateZ(0);
  }
  &:after {
    -webkit-transform: translateY(100%) translateZ(0);
    -ms-transform: translateY(100%) translateZ(0);
    transform: translateY(100%) translateZ(0);
  }
  &:hover::before {
    -webkit-transform: translateY(0) translateZ(0);
    -ms-transform: translateY(0) translateZ(0);
    transform: translateY(0) translateZ(0);
  }
  &:hover::after {
    -webkit-transform: translateY(0) translateZ(0);
    -ms-transform: translateY(0) translateZ(0);
    transform: translateY(0) translateZ(0);
  }
}
/*******************************************************************************
 * pink button
 ******************************************************************************/
.pink-button ,
a.pink-button {
  margin: 2rem;
  display: inline-block;
  border: none !important;
  background: $pinkHover;
  padding: 1rem 2rem;
  color: white;
  font-size: 100%;
  text-transform: uppercase;
  @include border-radius(0);
  &.blue-bg {
    &:hover {
      @include background-opacity($blueMenu,0.85);
    }
    &:before{
      background: background-opacity($blueMenu,0.55);
    }
    &:after {
      background: background-opacity($blueMenu,0.55);
    }
  }
}
/*******************************************************************************
 * load more button
 ******************************************************************************/
.pager-load-more > li > a {
  margin: 2rem;
  display: inline-block;
  border: none;
  background: $pinkHover;
  padding: 1rem 2rem;
  color: white;
  text-transform: uppercase;
  @include border-radius(0);
}
/*******************************************************************************
 * wraper-button
 * in center content inside
 ******************************************************************************/
.wraper-box-button {
  margin-bottom: 3rem;
  .wr-box {
    .wrb-tab {
      .wrb-cel {
        color: white;
        h3 {
          font-size: $sizeH3;
          font-weight: 400;
          &.f-size {
            font-size: $sizeH1;
          }
          &.no-border {
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
}
@charset "utf-8";

#footer-pre{
  background: $blueBack;
  padding: 4rem;
  .col-md-4{
    text-align: center;
    font-size: 25px;
    font-weight: 300;
    color: white;
    h3 {
      font-size: 25px;
      margin: 0 0 3rem;
      font-weight: 700;
      background-position: center top;
      background-repeat: no-repeat;
      padding-top: 10rem;
    }
    &:nth-child(1){
      h3 {
        background-image: url(../img/ico/ico-call-us.png);
      }
    }
    &:nth-child(2){
      h3 {
        background-image: url(../img/ico/ico-newsletter.png);
      }
    }
    &:nth-child(3){
      h3 {
        background-image: url(../img/ico/ico-write.png);
      }
    }
    #simple-subscription-form{
      .form-item-mail {
        padding: 0 1rem;
      }
      .simple_subscription_header,
      .simple_subscription_footer{
        display: none;
      }
    }
  }
}
#block-follow-site {
  margin-top: 4rem;
}
#footer-grid {
  background: $greyLight;
  font-size: $sizeSmall;
  padding-top: 5rem;
  padding-bottom: 5rem;

  .ctm-feedback {
    padding-top: 2rem;
    .ctools-modal-feedback-modal-style {
      float: right;
      margin: 0;
      font-size: 11px;
      padding: 0.3rem 2rem;
      @include border-radius(2rem);
      &:hover {
        background: darken($pinkHover, 10%);
      }
      &:before,
      &:after {

      }
    }
  }

  ul.menu {
    display: block;
    list-style: none;
    li {
      display: block;
      float: left;
      margin: 0 0.7rem 0 0;
      padding: 0;
      a {
        display: block;
        text-transform: uppercase;
        padding: 0.5rem;
        font-weight: 700;
        color: $baseBlack;
        &:hover {
          color: $blueHover;
        }
        &:first-child{
          padding-left: 0;
        }
      }
    }
    &:after{
      clear: both;
      content: ".";
      display: block;
      height: 0;
      visibility: hidden;
    }
  }
}
@charset "utf-8";


.taxonomy-term {
  .taxonomy-term-description {
    text-align: center;
    margin: 2rem 0;
  }
}
.wr-offer-wrap {
  background: $darkBlue1;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.vocabulary-regions {
  .wr-region-wrap {
    margin-bottom: 0;
    .wraper-box-button .wrb-cel h3 {
      font-size: $sizeH3XBig !important;
    }
  }
  .wr-offer-wrap  {
    margin-bottom: 0;
  }
}


.node-offer{
  .isotope-filter {

  }
  .desc {
    p {
      margin: 0 0 1rem;
      font-size: $sizeMediumXX;
    }
  }
  .day-label {
    padding: 1rem;
    color: white;
    text-transform: uppercase;
    font-size: $sizeSmall;
    font-weight: 400;
    background: $oragneBase;
  }
  .wr-triptype-wrap {
    text-align: center;
    .offer-triptype-icon {
      display: inline-block;
      cursor: pointer;
      margin: 3rem 1rem;
      img {

        display: inline-block;
      }
    }
  }
  .wr-image-wrap {
    margin-top: 6rem;
    margin-bottom: 6rem;

  }
  .wr-triplan-wrap {
    h5 {
      padding: 1rem;
      font-weight: 700;
      color: white;
      font-size: $sizeH3Small;
      background: $darkBlue;
    }
  }
  .wr-price-wrap {
    position: relative;
    &:after {
      content: ' ';
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin: 0 0 0 -4px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid white;
    }
    h3 {
      font-size: $sizeH3Big;
    }
    div.t-center.big {
      font-size: $sizeH1;
      font-weight: 700;
      margin:  2rem 0 4rem;
    }
    div.t-center.small {
      font-size: $sizeSmall;
      font-weight: 300;
      margin:  4rem 0 2rem;
      font-style: italic;
    }
  }
  .wr-region-wrap {
    margin: 0;
    .wr-box .wrb-tab .wrb-cel{
      h3.title-big {
        font-size: $sizeH1;
      }
      a { margin-left: 1rem; margin-right: 1rem;}
    }
  }
  .wr-additional-wrap {
    .content {
      width: 100%;
      .item {
        width: 50%;
        height: 100%;
        h4,
        .info-item {
          padding: 1.5rem;
        }
        h4 {
          margin: 0;
          color: white;
        }
      }
      .item-1 {
        border-right: 1rem solid white;
        background: $blueLabelLight;
        h4 {
          background: $blueLabel;
        }
      }
      .item-2 {
        border-left: 1rem solid white;
        background: $grayLabelLight;
        h4 {
          background: $grayLabel;
        }
      }
    }
  }

  &.node-teaser{
    .content {
      position: relative;
    }
  }

  .wraper-oferta {
    .icon-logo {
      &:after {
        position: absolute;
        display: block;
        content: ' ';
        width: 100px;
        height: 100px;
        bottom: -15px;
        right: -15px;
        background: url("../img/logo-small.png") no-repeat center;
      }
    }
    .tx-region {
      .country {
        font-size: $sizeMedium;
        &:after {
          content: ', ';
        }
        &:last-child {
          &:after {
            content: '';
          }
        }
      }
    }
  }

  .wr-recomended-offer {
    color: white;
    .wr-box {
      &:before {
        position: absolute;
        content: ' ';
        display: block;
        background: url("../img/logo-white.png") no-repeat center;
        width: 152px;
        height: 131px;
        z-index: 3;
        top: 7rem;
        left: 7%;
      }

      &:after {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        display: block;
        content: ' ';
        z-index: 2;
        background: -moz-linear-gradient(top, rgba(4,22,40,0.4) 0%, rgba(4,22,40,0.2) 60%, rgba(4,22,40,0.3) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(4,22,40,0.4) 0%,rgba(4,22,40,0.2) 60%,rgba(4,22,40,0.3) 100%);
        background: linear-gradient(to bottom, rgba(4,22,40,0.4) 0%,rgba(4,22,40,0.2) 60%,rgba(4,22,40,0.3) 100%);
      }
      .container {
        position: relative;
        z-index: 6;
      }
      h2 {
        font-size: $sizeH1;
        text-transform: uppercase;
        &:after {
          background: $pinkHover;
          content: " ";
          display: block;
          height: 2px;
          margin: 1rem auto;
          width: 20rem;
        }
      }
      .tx-desc {
        letter-spacing: 0.1em;
      }
      @media (max-width: 1199px) {
        h2 {
          font-size: $sizeH3XBig;
        }
        .tx-desc {
          letter-spacing: 0.05em;
        }
      }
      @media (max-width: 991px) {
        h2 {
          font-size: $sizeH3;
        }
        h3 {
          font-size: $sizeH3 - 3;
        }
        &:before {
          top: 3rem;
          left: 2%;
        }
        .tx-desc {
          letter-spacing: 0em;
        }
      }
      @media (max-width: 768px) {
        &:before {
          background-size: 80%;
        }
      }

      @media (max-width: 628px) {
        &:before {
          display: none;
        }
        .tx-desc {
          font-size: 13px;
          line-height: 15px;
          overflow: hidden;
          height: 3rem;
        }
      }
      @media (max-width: 468px) {
        .tx-desc {
          display: none;
        }
      }


    }
    img {
      width:  100%;
    }
  }

  .offer-form-wrap {
    position: relative;
    padding-top: 8rem;
    padding-bottom: 8rem;
    display: none;
    .form-item {
      display: inline-block;
      width: 25rem;
      &.form-type-item {
        display: block;
        width: 100%;
      }
      &.form-type-textarea {
        width: 50rem;
      }
      label {
        display: inline;
      }
      textarea,
      input[type="text"] {
        display: inline-block;
        width: 90%;
        padding: 1rem 2rem;
        height: auto;
        font-size: $sizeMedium;
        border: 1px solid #ededed;
      }
      textarea {
        width: 100%;
      }
    }
  }
}
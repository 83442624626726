@media (min-width: 992px) and (max-width: 1199px) {

}

@media (min-width: 768px) and (max-width: 991px) {

}
@media (max-width: 991px) {
  #header {
    #header-box {
      .box-wraper {
        position: inherit;
        &.isStuck {
          display: none;
          visibility: hidden;
        }
        #logo {
          left: -4rem;
        }
        #nav {
          ul.menu {
            li {
              margin: 0 0.1em;
              a {
                padding: 1rem 0.2em 1rem 0.1em;
                letter-spacing: -1px;
              }
            }
          }
        }
      }
    }
    .img-header h1 {
      top: 35%;
      span {
        display: none;
      }
    }
  }
  .node-hotel {
    &.node-teaser {
      .content {
        .desc {
          padding: 2rem;
          font-size: $sizeMedium;
          line-height: $sizeMedium + 2;
        }
      }
    }
  }
  .node-offer{
    .wraper-oferta {
      .icon-logo {
        &:after {
          width: 70px;
          height: 70px;
          bottom: -15px;
          right: -15px;
          background-size: 70px;
        }
      }
    }
  }
  .table-best-period-wrap-bg {
    width: 83%;
    left: 8%;
  }
  .table-best-period {
    .tbp-head {
      width: 3%;
      span.item {
        padding: 0.2rem 1.25rem 0 1rem;
        margin: 0.2rem 0.5rem 0.2rem 0;
      }
    }
    .tbp-content {
      .items {
        .item {
          padding-right: 1em;
          padding-left: 1em;
        }
      }
    }
  }
}
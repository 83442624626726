@charset "utf-8";
.image-region,
.banner-slider-oferta,
.banner-slider-taxo,
.img-header {
  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }
}
#header {
  position: relative;
  margin-bottom: 2rem;
  h1 {
    color: white;
    font-size: $sizeH1 + 1px;
    font-weight: 400;
    text-align: center;
    margin: 0;
    span {
      display: block;
      font-size: $sizeH3Small + 1px;
      font-weight: 400;
      font-style: normal;
      max-width: 900px;
      margin: 1rem auto;
      text-transform: none;
      &:before {
        display: block;
        background: $pinkHover;
        content: " ";
        height: 2px;
        margin: 1rem auto;
        width: 20rem;
      }
    }
    &.zar-tropikow ,
    &.dziedzictwo-swiata,
    &.zew-natury,
    &.romantycznie,
    &.rodzinnie,
    &.na-sportowo,
    &.najpiekniejsze-wyspy,
    &.metropolie-swiata,
    &.smak-rozrywki {
      span {
        &:before {
          display: none;
        }
      }
    }
  }
  .wrb-cel {
    h1 {
      margin-top: 7rem;
    }
  }

  #header-box{
    .box-wraper{
      top:0;
      z-index: 2;
      width: 100%;
      min-height: 111px;
      background: rgba(255,255,255,0.90);
      position: absolute;
      &.isStuck {
        z-index: 1005;
        @include box-shadow(0, 2px, 8px, rgba(0,0,0,0.2));
      }

      #logo {
        position: absolute;
        display: block;
        height: 111px;
      }
      .ctm-feedback {
        padding-top: 2rem;
        .ctools-modal-feedback-modal-style {
          float: right;
          margin: 0;
          font-size: 14px;
          padding: 0.5rem 1rem;
          @include border-radius(2rem);
          &:hover {
            background: darken($pinkHover, 10%);
          }
          &:before,
          &:after {

          }
        }
      }
      #nav{
        height: auto;
        margin: 0;
        ul.menu{
          display: block;
          margin: 0;
          padding: 2rem 0 0;
          display: block;
          &:after{
            clear: both;
            content: ".";
            display: block;
            height: 0;
            visibility: hidden;
          }
          li {
            display: block;
            float: left;
            margin: 0 0.2em;
            padding: 0;
            a{
              display: block;
              color: $blueMenu;
              font-size: $sizeMedium;
              text-transform: uppercase;
              font-weight: 700;
              padding: 1rem 0.5em 1rem 0.5em;
              &.active,
              &:hover {
                color: $oragneBase;
                // @include background-opacity(lighten($blueMenu,60%), 0.8);
              }
            }
          }
        }
      }
    }
  }
  .title-head-wraper {
    background: $blueLight;
    padding: 3rem 0;
    margin: 0;
  }
  .img-header{
    position: relative;
    h1 {
      position: absolute;
      z-index: 2;
      top: 50%;
      width: 100%;
    }
  }
}
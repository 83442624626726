@mixin blur($px: 0) {
  -webkit-filter: blur(#{$px}px) !important;
  -ms-filter: blur(#{$px}px) !important;
  -moz-filter: blur(#{$px}px) !important;
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'blur\'><feGaussianBlur stdDeviation=\'#{$px}\' /></filter></svg>#blur");
  filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='#{$px}');
}

@mixin brightness_element($val: 0) {
  -webkit-filter: brightness(#{$val} %);
  -moz-filter: brightness(#{$val} %);
  filter: brightness(#{$val} %);
}
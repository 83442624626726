@media (max-width: 567px)  {
  a.pink-button {
    margin: 1rem;
  }
  .wraper-box-button .wr-box .wrb-tab .wrb-cel h3 {
    font-size: $sizeH3 !important;
  }
  .view-content-triptype .wraper-box-button .wrb-cel h3 {
    font-size: $sizeMedium !important;
    &:before {
      background-size: 100px;
      width: 100px;
      height: 100px;
    }
  }
  .table-best-period {
    .tbp-content {
      .items {
        .item {
          padding-right: 0.25em;
          padding-left: 0.25em;
        }
      }
    }
  }

  .zar-tropikow,
  .dziedzictwo-swiata,
  .zew-natury,
  .romantycznie,
  .rodzinnie,
  .na-sportowo,
  .najpiekniejsze-wyspy,
  .metropolie-swiata,
  .smak-rozrywki {
    &:before {
      width: 80px;
      height: 80px;
      background-size: 80px;
      margin: 0.5rem auto;
    }
  }

  .wraper-box-button .wr-box .wrb-tab .wrb-cel h3.f-size {
    font-size: $sizeH1 - 20px;
  }
  .bx-controls-direction {
    display: none;
  }
  .node-inspirations.node-full {
    .wr-offer-wrap {
      .col-xs-4 {
        width: 100%;
      }
    }
  }
  .node-inspirations.node-full ,
  .taxonomy-term.vocabulary-regions {
    .wr-offer-wrap {
      .col-xs-4 {
        width: 100%;
      }
    }
  }
  .view-id-offer .offer-item.col-xs-6,
  .triptype-item.col-xs-6,
  .hotel-item.col-xs-4 {
    width: 100%;
  }
  .sub-menu {
    display: none;
  }
  #map-holder {
    height: 467px;
  }
  #header {

    h1 {
      font-size: $sizeH1 - 20px;
    }
    #header-box {
      .box-wraper {
        #logo {
          left: -10rem;
        }
      }
    }
  }
  .node-article.node-teaser {
    .col-xs-5,
    .col-xs-7 {
      width: 100%;
      text-align: center;
      margin-bottom: 2rem;
      img {
        display: inline-block;
      }
    }
  }
  .node-offer{
    .wraper-oferta {
      .tx-region .country {
        //font-size: $sizeSmall;
      }
    }
    .wr-additional-wrap {
      .content.wr-table {
        width: 100%;
        display: block;
        .item {
          width: 100%;
          display: block;
          margin: 0 0 2rem;
          border: none;
        }
      }
    }
  }
  .node-hotel {
    &.node-teaser {
      .content {
        .desc {
          display: none;
        }
      }
    }
  }
  #pagebottom-menu {
    .menu-item {
      a {
        h4 {
          margin-top: 0;
          font-size: $sizeH3Small - 2px;
        }
        p {
          display: none;
        }
      }
    }
  }
  .filter-options {
    &.row {
      margin-left: -1px;
      margin-right: -1px;
      .col-md-1 {
        padding-right: 1px;
        padding-left: 1px;
      }
    }
  }
}

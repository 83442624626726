.node-full {
  p {
    margin: 0;
    margin: 0;
  }
}
.node-page {
  .add-margin-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  &.node-full {
    .paragraf {
      margin-top: 5rem;
      margin-bottom: 5rem;
      h4 {
        margin: 0 0 3rem;
        font-weight: 400;
        font-size: $sizeH3;
        color: $darkBlue;
      }
    }
    h3 {
      color: white;
      font-weight: 400;
      text-transform: uppercase;
    }
    .paragraphs-item-foto-left-w-text {
      padding-top: 5rem;
      padding-bottom: 5rem;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
    }
    .paragraphs-item-foto-top-w-text {
      .field-name-field-ptwt-foto {
        text-align: center;
        margin: 0 0 2rem;
        img {
          display: inline-block;
        }
      }
    }
    .webform {
      background: $blueBack;
      padding: 5rem 0;
      margin-top: 5rem;
      margin-bottom: 5rem;
      input[type='text'],
      input[type='email'],
      textarea {
        @include border-radius(0);
        font-size: $sizeMedium;
        padding: 1rem 2rem;
        text-align: center;
        height: auto;
      }
    }
  }
}
@media (min-width: 767px) and (max-width: 992px) {
  .table-best-period {
    .tbp-content {
      .items {
        .item {
          padding-right: 1em;
          padding-left: 1em;
        }
      }
    }
  }
}


@media (max-width: 767px)  {
  .page-regions,
  .page-trip-time,
  .page-inspirations,
  .page-katalog-ofert,
  .page-triptypes {
    #pagebottom {
      padding: 2rem 0;
    }
  }
  .page-taxonomy-term-256,
  .page-taxonomy-term-257,
  .page-taxonomy-term-258,
  .page-taxonomy-term-259,
  .page-taxonomy-term-269,
  .page-taxonomy-term-261,
  .page-taxonomy-term-262,
  .page-taxonomy-term-263,
  .page-taxonomy-term-264,
  .page-node.node-type-offer {
    #header .wrb-cel h1 {
      margin-top: 0;
    }
  }
  .navbar-toggle {
    margin-top: 2.6rem;
  }
  .wraper-box-button .wr-box .wrb-tab .wrb-cel h3 {
    font-size: $sizeH3Small  !important;
  }
  .view-content-triptype .wraper-box-button .wrb-cel h3 {
    font-size: $sizeH3Small  !important;;
    &:before {
      background-size: 80px;
      width: 80px;
      height: 80px;
    }
  }

  .wraper-box-button .wr-box .wrb-tab .wrb-cel h3.f-size {
    font-size: $sizeH1 - 10px;
  }
  .sub-menu {
    margin-top: 2.6rem;
    ul {
      text-align: center;
      display: block;
      float: none;
      width: 100%;
      li {
        display: inline-block;
        float: none;
      }
    }
  }
  .table-best-period-wrap-bg {
    width: 83%;
    left: 9%;
  }
  .table-best-period {
    .tbp-head {
      width: 3%;
      span.item {
        padding: 0.2rem 1.25rem 0 1rem;
        margin: 0.2rem 0.5rem 0.2rem 0;
      }
    }
    .tbp-content {
      .items {
        .item {
          padding-right: 0.5em;
          padding-left: 0.5em;
        }
      }
    }
  }
  .page-regions #main-box {
    padding-left: 0;
    padding-right: 0;
  }
  .page-regions #breadcrumb-box {
    display: none;
  }
  .image-region,
  .banner-slider-oferta,
  .banner-slider-taxo,
  .img-header {
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
  }

  #block-views-regions-block-1 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .front #header {
    margin-bottom: 0;
  }
  #block-views-offer-block-1 {
    margin-left: 15px;
    margin-right: 15px;
  }
  #map-holder {
    height: 567px;
  }

  #header {
    margin-bottom: 0;
    h1 {
      font-size: $sizeH1 - 10px;
    }

    .wrb-cel {
      h1 {
        margin-top: 0;
      }
    }
    #header-box {
      .box-wraper {
        .ctm-feedback {
          display: none;
        }
        .nav-right-box {
          float: none;
        }
        #logo {
          left: -8rem;
        }
        #nav {
          ul.menu {
            padding-top: 2.6rem;
            li {
              margin: 0.2rem 0;
              float: none;
              a {
                padding: 1rem;
                letter-spacing: 0;
                text-align: center;
                border-bottom: 1px solid #f6f6f6;
                &:hover {
                  background: #fafafa;
                }
              }
              &:last-child {
                a {
                  border-bottom: none;
                }

              }
            }
          }
        }
      }
    }
  }
  .node-offer{
    .wraper-oferta {
      .tx-region .country {

      }
      .icon-logo {
        &:after {
          width: 60px;
          height: 60px;
          bottom: -10px;
          right: -10px;
          background-size: 60px ;
        }
      }
    }
  }
  .node-hotel {
    &.node-teaser {
      .content {
        .ndh-wrap {
          padding: 2rem;
          text-align: center;
          font-weight: 700;
          h5 {
            text-transform: uppercase;
            font-size: $sizeH3Small - 2;
            height:  5rem;
          }
          .text-short {
            height: 6rem;
          }
        }
        .desc {
          padding: 2rem;
          font-size: $sizeMedium - 2;
          line-height: $sizeMedium;
        }
      }
    }
  }
  #pagebottom-menu {
    .menu-item {
      a {
        h4 {
        }
        p {
          font-size: $sizeMedium - 3;
          line-height: $sizeMedium - 1;
          padding: 0 1rem;
        }
      }
    }
  }
  #box-pagetop {
    display: none;
  }
  .front #breadcrumb-box {
    display: none;
  }
  #box-view-region-top {
    #block-views-regions-block-1 {
      margin-top: 0;
      margin-bottom: 0;
    }
    display: block;
  }
  #footer-pre {
    .col-md-4 {
      margin-top: 5rem;
      margin-bottom: 5rem;
    }
  }
  #footer-grid {
    ul.menu {
      li {
        display: block;
        float: none;
        margin: 1.5rem 0;
        padding: 0;
        a {
          text-transform: uppercase;
          font-size: $sizeH3;
          text-align: center;
        }
      }
    }
    #block-copyr-my-copyr {
      margin-top: 3rem;
      text-align: center;
    }
    #footer-col-2 {
      text-align: center;
    }
  }

  .filter-options {
    &.row {
      margin-left: -3px;
      margin-right: -3px;
      .col-md-1 {
        padding-right: 3px;
        padding-left: 3px;
      }
    }
  }

}

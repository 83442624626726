@charset "utf-8";

/*******************************************************************************
 * view-all
 ******************************************************************************/
.grid-item {
  float: left;
  width: 300px;
  height: 200px;
  overflow: hidden;
}
.wraper-box-button {
  .wrb-cel {
    h3 {
      font-size: $sizeH3Small;
      font-weight: 400;
      &:after {
        width: 50px;
        height: 2px;
        display: block;
        background: $pinkHover;
        content: ' ';
        margin: 1rem auto;
      }
    }
  }
  &.wr-region-wrap {
    .wrb-cel h3 {
      &:after {
        display: none;
      }
    }
  }
  &.wraper-inspiration {
    .wrb-cel h3 {
      font-size: $sizeH3Small !important;
    }
  }
}
.view {
  // default
  .view-header {
    // Views - filter
    .view-id-regionsfilter {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
    & > p {
      margin-top: 3rem;
      margin-bottom: 6rem;
      font-size: $sizeMediumXX;
      text-align: center;
    }
  }
  .view-footer {
    & > p {
      font-size: $sizeMediumXX;
      text-align: center;
    }
  }
}
.views-exposed-widget .nano { height: 220px; }

.zar-tropikow,
.dziedzictwo-swiata,
.zew-natury,
.romantycznie,
.rodzinnie,
.na-sportowo,
.najpiekniejsze-wyspy,
.metropolie-swiata,
.smak-rozrywki {
  &:before {
    width: 100px;
    height: 100px;
    display: block;
    background: center no-repeat;
    border-bottom: 2px solid $pinkHover;
    content: ' ';
    margin: 1rem auto;
  }
}

.zar-tropikow {
  &:before{
    background-image: url(../img/ico/triptype/triptype-1_1.png);
  }
}
.dziedzictwo-swiata {
  &:before{
    background-image: url(../img/ico/triptype/triptype-2_1.png);
  }
}
.zew-natury {
  &:before{
    background-image: url(../img/ico/triptype/triptype-3_1.png);
  }
}
.romantycznie {
  &:before{
    background-image: url(../img/ico/triptype/triptype-4_1.png);
  }
}
.rodzinnie {
  &:before{
    background-image: url(../img/ico/triptype/triptype-5_1.png);
  }
}
.na-sportowo {
  &:before{
    background-image: url(../img/ico/triptype/triptype-6_1.png);
  }
}
.najpiekniejsze-wyspy {
  &:before{
    background-image: url(../img/ico/triptype/triptype-7_1.png);
  }
}
.metropolie-swiata {
  &:before{
    background-image: url(../img/ico/triptype/triptype-8_1.png);
  }
}
.smak-rozrywki {
  &:before{
    background-image: url(../img/ico/triptype/triptype-9_1.png);
  }
}

/*******************************************************************************
 * view-inspiration
 ******************************************************************************/
.view-inspiration {

  &.view-display-id-block_3 {
    .inspiration-item {
      blockquote {
        display: none;
      }
    }
  }
  .wraper-box-button {
    .wrb-cel {
      .tx-region,
      .tx-desc{
        font-weight: 700;
        margin: 0;
        font-size: $sizeMedium;
      }
    }
  }
}
/*******************************************************************************
 * view-triptype
 ******************************************************************************/
.view-content-triptype {
  .wraper-box-button {
    .wrb-cel {
      h3 {
        text-transform: uppercase;
        margin: 0 0 2rem;
        &:before {
          width: 100px;
          height: 100px;
          display: block;
          background: center no-repeat;
          border-bottom: 2px solid $pinkHover;
          content: ' ';
          margin: 1rem auto;
        }
        &:after {
          display: none;
          border: none;
        }
      }
    }
  }

  @for $i from 1 through 9 {
    .triptype-item:nth-child(#{$i}) {
      .wrb-cel{
        h3:before{
          background-image: url(../img/ico/triptype/triptype-#{$i}_1.png);
        }
      }
    }
  }
}
#block-views-triptype-block-1 {
  margin-bottom: 3rem;
}
/*******************************************************************************
 * view-regions
 ******************************************************************************/
#block-views-regions-block-1 {
  padding: 3rem 2rem;
  background: $blueLight;
  color: white;
  margin: 7rem 0;
  h3 {
    text-align: center;
    font-weight: 700;
    font-size: $sizeH3Big;
    text-transform: uppercase;
  }
  .view-content {
    .views-row {
      font-size: $sizeMedium;
      a {
        color: white;
        display: block;
        padding: 0.1em 1rem 0.1em 1.5rem;
        &:hover {
          color: $oragneBase;
          background: darken( $blueLight, 5%);
        }
      }
    }
  }
}

/*******************************************************************************
 * Wyszukiwarka offert
 ******************************************************************************/
.offer-items {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
@charset "utf-8";

input[type="text"]{
  border: none;
  padding: 1rem;
  color: $greyBase;
  font-size: 20px;
  line-height: normal;
  width: 100%;
}

input[type="submit"]{
  display: inline-block;
  border: none;
  background: $pinkBase;
  text-transform: uppercase;
  padding: 1rem 1.5rem;
  color: white;
  font-weight: 300;
  font-size: 16px;
}

.views-exposed-form {
  .views-exposed-widgets {
    #edit-ofe-rec-wrapper,
    #edit-ofe-par-wrapper {
      display: none;
    }

    @include container(12);
    .views-widget-filter-field_of_region_tid,
    .views-widget-filter-field_of_trip_tid,
    .views-widget-filter-field_nd_triptime_tid,
    .views-widget-filter-field_of_date_value {
      @include span(31%);
      @include gutters(0rem);
      @include gutters(0rem inside);
      @media (min-width: 992px) and (max-width: 1199px) {
        @include span(30.6%);
        @include gutters(0rem);
        @include gutters(0rem inside);
      }
      @media (min-width: 768px) and (max-width: 991px) {
        @include span(29.8%);
        @include gutters(0rem);
        @include gutters(0rem inside);
      }
      @media (max-width: 767px) {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        float: none;
      }
      @media (max-width: 567px) {
        width: 100%;
      }
      border-right: 1px solid white;
      .views-widget {
        select {
           display: none;
        }
        button.btn  {
          width: 100%;
          display: block;
          border:  none;
          background: #808080;
          @include border-radius(0px);
          font-size: $sizeMedium;
          color: white;
          font-weight: 400;
          text-align: left;
          padding: 1rem 2rem;
          text-transform: uppercase;
          &.bt-active {
            background: $oragneBase;
          }
          .caret {
            float: right;
            //margin-top: -10px;
            //-webkit-margin-before: 10px;
            text-align: right;
          }
        }
        .dropdown-menu {
          width: 100%;
          margin: 1rem 0 0 ;
          padding: 0;
          background: $oragneBase;
          border: none;
          @include box-shadow(none);
          @include border-radius(0);
          &:before {
            position: absolute;
            right: 3rem;
            top: -5px;;
            width: 0;
            height: 0;
            display: block;
            content: ' ';
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid $oragneBase;
          }

          ul, li, a {
            display: block;
            margin: 0%;
            padding: 0;
          }
          ul > li {
            border-bottom: 1px solid lighten($oragneBase, 5%);
            a {
              padding: 0.5rem 2rem;
              color: white;
              font-size: $sizeMedium;
              &:hover {
                background: darken($oragneBase, 10%);
              }
            }
          }

        }
      }
    }
    .views-widget-filter-field_of_region_tid {

    }
    .views-widget-filter-field_nd_triptime_tid {
     // border-right: none;
    }
    .views-widget-filter-field_of_trip_tid{

    }

    // wyjatek dla daty
    .views-widget-filter-field_of_date_value {
      .views-widget {
        @include container(4);
        .custom-drop {
          @include span(50%);
          @include gutters(0rem);
          @include gutters(0rem inside);
          &:first-child {
            border-right: 1px solid white;
          }
        }
      }
    }
    .views-submit-button {
      text-align: right;
      float: left;
      width: 7%;
      padding: 0.8rem 0 0;
      font-weight: 400;
      @media (max-width: 767px) {
        float: none;
        display: block;
        width: 100%;
        text-align: center;
      }
      #edit-submit-offer {
        margin: 0;
        display: inline-block;
        font-size: $sizeMedium;
        color: white;
        font-weight: 700 !important;
        text-align: center;
        padding: 1.1rem 1rem;
        height: auto;
      }
    }
  }
}


@charset "utf-8";
#block-footerinfo-footerinfo {
  .feedback-modal-link {
    padding: 1rem 1.5rem;
    font-size: $sizeMedium;
  }
}
#modalBackdrop.backdrop-feedback {
  background-color: rgba(0,0,0,0.5) !important;
  opacity: 1 !important;
}
#modalContent.modal-feedback {
  .ctools-modal-feedback-modal-content {
    background: none;
    border: none;
    .popups-close a.close{
      display: block;
      width: 2rem;
      height: 2rem;
      background: grey;
    }
    .modal-content {
      padding: 3rem;
      textarea,
      input[type="text"] {
        border: 1px solid silver;
        padding: 1em;
        font-size: $sizeMedium;
      }
      input[type="text"] {
        height: auto;
      }
    }
  }
}
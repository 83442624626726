@charset "utf-8";

img.full-width{
  width: 100%;
}

.rating {
  unicode-bidi: bidi-override;
  //direction: rtl;
  margin: 1rem 0;
  font-size: $sizeH3Small;
}
.rating > span {
  display: inline-block;
  position: relative;
  width: 1.1em;
  color: $yellowStar;
  font-size: $sizeH3Small !important;

}
.rating > span.active:before,
//.rating > span.active ~ span:before
{
  content: "\2605";
  position: absolute;
}
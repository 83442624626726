@charset "utf-8";

// ==================================================
// Color
// ==================================================

$baseBlack: #555;

$greyLight: #f2f1ed;
$greyLight2: #f5f4ef;
$greyBase: #636363;
$blueLight:#0098fe;
$darkBlue: #0055a6;
$darkBlue1: #013b69;

$blueLabel: #2e8de9;
$blueLabelLight: #eaf2fd;

$grayLabel: #a0a0a0;
$grayLabelLight: #fbfbfb;

$blueMenu: #0083ca;
$blueBack: #0099cb;
$pinkBase: #ff0198;
$pinkHover:  #ec168e;
$yellowMedium: #f3b442;
$yellowStar: #efb059;


$oragneBase: #eeac3b;

$chartMain: #fff4e2;
$chartBG1: #cdcec6;
$chartBG2: #888e93;
$chartBG3: #4f6c7e;
$chartBG4: #083a5b;

$chartBG: $chartBG1, $chartBG2, $chartBG3, $chartBG4;

$blue: #09c;
$blueHover: darken( $blue, 10% );
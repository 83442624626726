/**
 * $BUTTON
 */
$color_start: rgba(0,0,0,1);
$color_stop:  rgba(0,0,0,0);

@mixin linearGradient($top, $bottom){
  background: $top; /* Old browsers */
  background: -moz-linear-gradient(top,  $top 0%, $bottom 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  $top 0%,$bottom 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  $top 0%,$bottom 100%); /* IE10+ */
  background: linear-gradient(to bottom,  $top 0%,$bottom 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

@mixin buton-image($x-scale, $y-scale) {
  width: 100%;
  height: 100%;
  .wr-box {
    z-index: 5;
    background: -moz-linear-gradient(top, rgba(4,22,40,0.5) 0%, rgba(4,22,40,0.3) 60%, rgba(4,22,40,0.4) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(4,22,40,0.5) 0%,rgba(4,22,40,0.3) 60%,rgba(4,22,40,0.4) 100%);
    background: linear-gradient(to bottom, rgba(4,22,40,0.5) 0%,rgba(4,22,40,0.3) 60%,rgba(4,22,40,0.4) 100%);
  }
  .image-waper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    z-index: 1;
    img {
      @include transition(all 0.3s ease-in-out );
    }
    blockquote {
      visibility: hidden;
      display: none;
    }

    // ------------------
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      //background-color: black;
      height: 50%;
      width: 100%;
      z-index: 2;
      opacity: 1;
      overflow: hidden;
      cursor: pointer;
      @include transition(all 0.2s ease-in-out);
    }

    &:before {
      @include linearGradient($color_stop, $color_start);
      top: 100%;
    }

    &:after {
      @include linearGradient($color_start, $color_stop);
      bottom: 100%;
    }
    // ------------------
  }
  &:hover {
    .image-waper {
      img {
        @include transform(scale($x-scale, $y-scale));
        @include blur(2);
      }

      &:before,
      &:after {
        opacity: 0.4;
        cursor: pointer;
      }

      &:before {
        @include transform(translateY(-100%) translateZ(0));
      }

      &:after {
        @include transform(translateY(100%) translateZ(0));
      }
    }
  }
}
@mixin button-text($color, $color-hover, $bg-color, $bg-color-hover, $border-size: 0, $border-color: transparent, $border-color-hover: transparent) {
  overflow: hidden;
  position:relative;
  z-index: 1;
  color: $color;
  border: $border-size solid $border-color;
  @include border-radius(4px);
  @include transition(all 0.4s ease-in-out);

  &:hover {
    color: $color-hover;
    background-color: $bg-color-hover;
    border: $border-size solid $border-color-hover;
  }

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    background-color: $bg-color;
    height: 50%;
    width: 100%;
    z-index: -2;
    opacity: 1;
    overflow: hidden;
    cursor: pointer;
    @include transition(all 0.4s ease-in-out);
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  &:hover::before,
  &:hover::after {
    opacity: 0;
    cursor: pointer;
  }

  &:hover::before {
    @include transform(translateY(-100%) translateZ(0));
  }

  &:hover::after {
    @include transform(translateY(100%) translateZ(0));
  }
}

/*
    &:before,
    &:after {
      content: " ";
      display: block;
      position: absolute;

      background: rgba(0,0,0,0.4);
      left: 0;
      height: 50%;
      width: 100%;
      z-index: 3;
      z-index: -2;
      opacity: 1;
      overflow: hidden;
      cursor: pointer;
      @include transition(all 0.4s ease-in-out);
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }

    &:hover::before,
    &:hover::after {
      opacity: 0;
      cursor: pointer;
    }

    &:hover::before {
      @include transform(translateY(-100%) translateZ(0));
    }

    &:hover::after {
      @include transform(translateY(100%) translateZ(0));
    }
    */
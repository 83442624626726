@charset "utf-8";

#map-holder {
  margin:  2rem 0 4rem;
  height: 672px;
}

.wr-table {
  display: table;
}
.wr-cel{
  display: table-cell;
}

.set-shadown-1{
  @include text-shadow(1px, 1px, 1px,  rgba(black, 0.4));
}

.navbar-toggle {
  border: none;
  @include border-radius(0);

  .icon-bar {
    width: 40px;
    height: 6px;
    margin-top: 5px !important;
    @include border-radius(0);
    background-color: $blueLight !important;
  }
  &:focus,
  &:hover {
    background-color: lighten(#ddd,10%) !important;
    .icon-bar {
      background-color: darken($blueLight,10%) !important;
    }
  }
}

.block-views > h3,
h3.title-inside {
  text-align: center;
  font-weight: 700;
  font-size: $sizeH3;
  margin: 3rem 0;
  text-transform: uppercase;
}
h3.titel-offer {
  margin: 0 1rem 3rem 1rem;
  color: white;
  font-size: $sizeH3;
  text-transform: uppercase;
  font-weight: 700;
}
#box-view-region-top {
  display: none;
}

/*******************************************************************************
 * padding margin for description
 ******************************************************************************/
.taxonomy-term {
  .taxonomy-term-description {
    p {
      margin: 0 0 1rem;
      font-size: $sizeMediumXX;
    }
  }
}
.node-inspirations.node-full {
  .field-name-body {
    p {
      margin: 0 0 1rem;
      font-size: $sizeMediumXX;
    }
  }
}


.tooltip.top {
  .tooltip-arrow {
    border-top-color: $yellowMedium;
  }
  .tooltip-inner {
    @include border-radius(0);
    padding: 0.5rem 3rem;
    text-align: center;
    font-weight: 400;
    font-size: $sizeMedium;
    text-transform: uppercase;
    background: $yellowMedium;
  }
}

.node-type-offer .tooltip.top {
  .tooltip-arrow {
    border-top-color: $blueLabel;
  }
  .tooltip-inner {
    background: $blueLabel;
  }
}